.forms{
  display: flex;
  
  flex-direction: column;
  align-items: start;
  border-radius: 2px;
  border-color: rgb(72, 255, 0);
  width: 30vw;
}

.check{
  display: flex;
  
  flex-direction: row;
  justify-content: center;
  align-items: center;
 
  width: 30vw;
}
.inp{
  width: 350px;
}